import { delay } from 'redux-saga';
import {
  call, put, select, takeLatest
} from 'redux-saga/effects';
import { reset } from 'redux-form';
import { ActionTypes } from '../constants';
import { ActionTypes as SharedActionTypes } from 'shared/constants';
import IsUser from 'shared/utils/user/IsUser';
import {isSp} from '../utils/isSp'
import utils from '../utils';
import notification from '../utils/notifications';
import Api from '../api';
import moment from 'moment';
import { history, projectsMy, spProjects } from 'shared/routes/urlLocations';
import formModels from '../constants/formModels';
import patternSaga from './patternSaga';

const projectInitialModel = Object.assign({}, formModels.issuerProjectCreation);

export function* issuerGetFullProject({ id }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  try {
    yield put({ type: ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST_STARTED });
    const {
      projects: { initialProjectValues }
    } = yield select();

    const { response, request, status } = yield call(
      Api.Projects.getProjectDetails,
      `${utils.config.apiProjectUrl}/projects/${id}?projection=all`
    );
    if (request.status && request.status === 200) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST_SUCCESS,
        project: { ...response, bankAccount: JSON.stringify(response.bankAccount) },
        editing: true
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({ type: ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST_FAILURE });
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST_FAILURE,
      payload: {
        message: error.message,
        statusCode: error.status
      }
    });
    yield call(notification, {
      type: 'error',
      data: 'Project edit error'
    });
  } finally {
    yield put({ type: ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST_FINISHED });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

export function* issuerProjectEdit({ values, id }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  let formatedData = '';
  if (values.endDate && values.endDate.format) {
    formatedData = `${values.endDate.format('YYYY-MM-DD')}T23:59:59Z`;
  } else {
    formatedData = values.endDate;
  }
  const {
    projects: {
      editing, editingProject, savingProject, initialProjectValues
    }
  } = yield select();

  let data = {
    ...values,
    targetedReturns: editingProject.targetedReturnsDocument ? null : values.targetedReturns,
    fullDescription: editingProject.fullDescriptionDocument ? null : values.fullDescription,
    targetedReturnsDocument: editingProject.targetedReturnsDocument,
    fullDescriptionDocument: editingProject.fullDescriptionDocument,
    warningAndDisclaimerDocument: editingProject.warningAndDisclaimerDocument,
    endDate: formatedData,
    picture: Object.assign({}, editingProject.picture),
    pictureId: editingProject.picture ? editingProject.picture.id : null,
    regions: values.regions ? values.regions : editingProject.regions,
    category: values.category ? values.category : editingProject.category,
    industrySector: values.industrySector ? values.industrySector : editingProject.industrySector,
    id: id,
    createdBy: editingProject.createdBy,
    bankAccount: values.bankAccount ? JSON.parse(values.bankAccount) : null,
    ndaForDataRoomWithApproval: !values.ndaForDataRoom
      ? values.ndaForDataRoom
      : values.ndaForDataRoomWithApproval
  };

  if (!data.pictureId && (initialProjectValues.picture && initialProjectValues.picture.id)) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE,
      id: initialProjectValues.picture.id
    });
  }

  yield delay(2000);

  try {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_STARTED,
      savingStatus: ''
    });
    const { response, request, status } = yield call(
      Api.Projects.issuerProjectEdit,
      `${utils.config.apiProjectUrl}/projects/${initialProjectValues.id || id}`,
      data
    );
    if (request.status === 200) {
      yield put(reset('issuerProjectCreation'));

      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_SUCCESS,
        savingStatus: 'saveSuccess',
        data: {
          ...projectInitialModel,
          ...response,
          bankAccount: JSON.stringify(response.bankAccount)
        }
      });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_FAILURE,
        savingStatus: 'saveError'
      });
      yield call(notification, {
        type: 'error',
        data: response,
        parse: true
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_FAILURE,
      payload: {
        message: error.message,
        statusCode: error.status
      },
      savingStatus: 'saveError'
    });
    yield call(notification, {
      type: 'error',
      data: 'Project edit error'
    });
  } finally {
    yield put({ type: ActionTypes.ISSUER_PROJECT_EDIT_FINISHED });
    // if (data.pictureId === null && !!initialProjectValues.picture) {
    //   yield put({
    //     type: ActionTypes.ISSUER_PROJECT_REMOVE_PICTURE_FROM_STORAGE,
    //     id: !!initialProjectValues.picture ? initialProjectValues.picture.id : null
    //   });
    // }
    // else {
    //   yield put({ type: ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST, id });
    // }
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

export function* issuerEditProjectUploadFilesLinks() {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const {
    projects: {
      editingProject: { files },
      editingProject
    }
  } = yield select();

  const data = { files };

  try {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_STARTED
    });
    const { response, request, status } = yield call(
      Api.Projects.issuerProjectEditFiles,
      `${utils.config.apiFilesUrl}/projects/${editingProject.id}`,
      data
    );
    if (request.status === 200 || request.status === 204) {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_SUCCESS,
        data,
        savingStatus: 'saveSuccess'
      });
      // yield put({ type: ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST, id: editingProject.id });
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_FAILURE,
        savingStatus: 'saveError'
      });
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_FAILURE,
      payload: {
        message: error.message,
        statusCode: error.status
      },
      savingStatus: 'saveError'
    });
    yield call(notification, {
      type: 'error',
      data: 'Project update error'
    });
  } finally {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS_FINISHED
    });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

export function* issuerEditProjectPublish({ values }) {
  const {
    projects: { editingProject },
    user: {
      info: { userType }
    }
  } = yield select();
  const isUser = new IsUser(userType);

  let data;
  let url;
  if (!editingProject.published) {
    url = `${utils.config.apiProjectUrl}/projects/${editingProject.id}`;
    data = {
      ...values,
      ...editingProject,
      published: true,
      approvedStatus: 'PENDING',
      publishedDate: moment.utc(moment().format()),
      bankAccount: editingProject.bankAccount ? JSON.parse(editingProject.bankAccount) : null
    };
  } else {
    url = `${utils.config.apiFilesUrl}/projects/${editingProject.id}`;
    data = {
      files: editingProject.files,
      published: true
    };
  }
  delete data.rejectionsHistory;
  try {
    let api;
    editingProject.published
      ? (api = Api.Projects.issuerProjectEditPublish)
      : (api = Api.Projects.issuerProjectPublish);

    yield put({ type: ActionTypes.ISSUER_PROJECT_EDIT_PUBLISH_STARTED });

    const { response, request, status } = yield call(api, url, data);
    const isSpCheck = yield call(isSp);
    const notificationClass = isSpCheck
            ? ''
            : 'ant-notification-success ant-notification-notice-description';

    if ((request.status && request.status === 200) || request.status === 204) {
      if (!editingProject.published && !isUser.issuerSP) {
        yield call(notification, {
          type: 'success',
          className: notificationClass,
          data: {
            description:
              'Your project was created successfully and is currently under review.'
          }
        });
      }
      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_PUBLISH_SUCCESS,
        data: Object.assign({}, response, { errorMessage: 'ok' }),
        savingStatus: 'publishSuccess'
      });
      (userType && userType === 'ADMIN_STRATEGIC_PARTNER')
      || userType === 'WORKER_STRATEGIC_PARTNER'
      || userType === 'COMPLIANCE_OFFICER_STRATEGIC_PARTNER'
        ? history.push(spProjects)
        : history.push(`${projectsMy}${editingProject.published ? '#ongoing' : '#pending'}`);
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypes.USER_LOGOUT_REQUEST });
    } else {
      yield put({
        type: ActionTypes.ISSUER_PROJECT_EDIT_PUBLISH_FAILURE,
        payload: { message: response },
        savingStatus: 'publishError'
      });
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (error) {
    yield put({
      type: ActionTypes.ISSUER_PROJECT_EDIT_PUBLISH_FAILURE,
      payload: {
        message: error.message,
        statusCode: error.status
      },
      savingStatus: 'publishError'
    });
    yield call(notification, {
      type: 'error',
      data: 'Project update error'
    });
  } finally {
    yield put({ type: ActionTypes.ISSUER_PROJECT_EDIT_PUBLISH_FINISHED });
  }
}

const getFileCategoriesConfig = {
  requestFunction: Api.Projects.fileCategories,
  requestUrl: `${utils.config.apiFileCategoriesUrl}`,
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true ,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
  }
};

export function* watchGetFileCategories() {
  yield takeLatest(SharedActionTypes.GET_FILE_CATEGORIES, patternSaga, getFileCategoriesConfig);
}

export function* issuerGetFullProjectWatch() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_GET_FULL_REQUEST, issuerGetFullProject);
}

export function* watchIssuerEditProjectUploadFilesLinks() {
  yield takeLatest(
    ActionTypes.ISSUER_PROJECT_EDIT_UPLOAD_FILES_LINKS,
    issuerEditProjectUploadFilesLinks
  );
}

export function* issuerProjectEditWatch() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_EDIT, issuerProjectEdit);
}

export function* watchIssuerEditProjectPublish() {
  yield takeLatest(ActionTypes.ISSUER_PROJECT_EDIT_PUBLISH, issuerEditProjectPublish);
}
