import { takeLatest } from 'redux-saga/effects';
import {
  call, put, select, all
} from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import notification from '../utils/notifications';
import Api from '../api';
import { history, stubPage, login } from 'shared/routes/urlLocations';
import patternSaga from './patternSaga';
import { getPrivateBankersListAction } from '../actions/userDataActions';
import { togglePrivateBankerModal } from '../actions/modalsActions';
import { ActionTypes as sharedActionTypes } from 'shared/constants';
import { getSpPlatformConfig } from 'shared/actions/spPlatformConfigActions';
import _ from 'lodash';
import { isSp } from '../utils/isSp';

// const notificationText = email => `Confirmation e-mail will be sent to ${email}`;
const notificationText = email => `Confirmation link will be sent to your e-mail soon.
  If you have not received the message to confirm your e-mail address,
  please check your spam or junk mail folder.`;

function* issuerRegistration(data) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const isSpCheck = yield call(isSp);
  const notificationClass = isSpCheck
    ? ''
    : 'ant-notification-success ant-notification-notice-description';

  try {
    let reCaptchaToken = sessionStorage.getItem("recaptchaToken");
    const { response, request, status } = yield call(
      Api.Registration.userRegistration,
      `${utils.config.apiUserUrl}/public/register`,
      { data: { ...data.value, type: 'COMPANY', userType: 'ISSUER', reCaptchaToken: reCaptchaToken } }
    );
    if (request.status && (request.status === 200 || request.status === 204)) {
      const description = notificationText(data.value.email);
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: { description }
      });
      yield history.push('/login');
    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* issuerEdit(data) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const isSpCheck = yield call(isSp);
  const notificationClass = isSpCheck
    ? ''
    : 'ant-notification-success ant-notification-notice-description';
  try {
    const { response, request, status } = yield call(
      Api.Registration.userRegistration,
      `${utils.config.apiUserUrl}/edit`,
      { data: { ...data.value, type: 'COMPANY', userType: 'ISSUER' } }
    );
    if (request.status && (request.status === 200 || request.status === 204)) {
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: { description: 'Edits saved successfully, your changes will be reviewed.' }
      });
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* investorRegistration(data) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const isSpCheck = yield call(isSp);
  const notificationClass = isSpCheck
    ? ''
    : 'ant-notification-success ant-notification-notice-description';
  try {
    let reCaptchaToken = sessionStorage.getItem("recaptchaToken");
    const { response, request, status } = yield call(
      Api.Registration.userRegistration,
      `${utils.config.apiUserUrl}/public/register`,
      { data: { ...data.value, isUSCitizen: !!data.value.isUSCitizen, type: 'USER', reCaptchaToken: reCaptchaToken } }
    );
    console.log(response)

    if (request.status && (request.status === 200 || request.status === 204)) {
      const description = notificationText(data.value.email);
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: { description }
      });
      yield history.push('/login');
    } else if (response[0].code === 1109) {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
      yield history.push('/login');
    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* investorCompanyRegistration(action) {
  try {
    let reCaptchaToken = sessionStorage.getItem("recaptchaToken");
    yield put({
      type: ActionTypes.INVESTOR_COMPANY_REGISTRATION_REQUEST_STARTED
    });
    yield put({ type: ActionTypes.SHOW_SPINNER });
    const isSpCheck = yield call(isSp);
    const notificationClass = isSpCheck
      ? ''
      : 'ant-notification-success ant-notification-notice-description';

    const { values } = action;
    const {
      user: { info }
    } = yield select();
    values.userType = info.userType;
    values.type = 'COMPANY';
    const params = { ...values, reCaptchaToken: reCaptchaToken };
    const { response, request, status } = yield call(
      Api.Registration.fullInvestorRegistration,
      `${utils.config.apiUserUrl}/register`,
      params
    );
    if (request.status && (request.status === 200 || request.status === 204)) {
      const description = notificationText(values.email);
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: { description: 'Information was saved.' }
      });
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({
      type: ActionTypes.INVESTOR_COMPANY_REGISTRATION_REQUEST_FINISHED
    });
  }
}

function* investorCompanyEdit(action) {
  try {
    yield put({
      type: ActionTypes.INVESTOR_COMPANY_EDIT_REQUEST_STARTED
    });
    yield put({ type: ActionTypes.SHOW_SPINNER });

    const { values } = action;
    const {
      user: { info }
    } = yield select();
    values.userType = info.userType;
    values.type = 'COMPANY';
    const params = { ...values };
    const { response, request, status } = yield call(
      Api.Registration.fullInvestorRegistration,
      `${utils.config.apiUserUrl}/edit`,
      params
    );
    const isSpCheck = yield call(isSp);
    const notificationClass = isSpCheck
      ? ''
      : 'ant-notification-success ant-notification-notice-description';
    if (request.status && (request.status === 200 || request.status === 204)) {
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: { description: 'Edits saved successfully, your changes will be reviewed.' }
      });
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({
      type: ActionTypes.INVESTOR_COMPANY_EDIT_REQUEST_FINISHED
    });
  }
}

function* investorIndividualRegistration(action) {
  try {
    let reCaptchaToken = sessionStorage.getItem("recaptchaToken");
    yield put({
      type: ActionTypes.INVESTOR_INDIVIDUAL_REGISTRATION_REQUEST_STARTED
    });
    yield put({ type: ActionTypes.SHOW_SPINNER });

    const { values } = action;
    const {
      user: { info }
    } = yield select();
    values.userType = info.userType;
    values.type = 'INDIVIDUAL';
    const params = { ...values, reCaptchaToken: reCaptchaToken };
    if (typeof params.birthDate === 'string') {
      params.birthDate = values.birthDate
    } else {
      params.birthDate = values.birthDate.format('YYYY-MM-DD');
    }

    if (values.passportExpiry) {

      if (typeof params.birthDate === 'string') {
        params.passportExpiry = values.passportExpiry
      } else {
        params.passportExpiry = values.passportExpiry.format('YYYY-MM-DD');

      }
    }
    const { response, request, status } = yield call(
      Api.Registration.fullInvestorRegistration,
      `${utils.config.apiUserUrl}/register`,
      params
    );
    const isSpCheck = yield call(isSp);
    const notificationClass = isSpCheck
      ? ''
      : 'ant-notification-success ant-notification-notice-description';

    if (request.status && (request.status === 200 || request.status === 204)) {
      const description = notificationText(values.email);
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: { description: 'Information was saved.' }
      });
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({
      type: ActionTypes.INVESTOR_INDIVIDUAL_REGISTRATION_REQUEST_FINISHED
    });
  }
}

function* investorIndividualEdit(action) {
  try {
    yield put({
      type: ActionTypes.INVESTOR_INDIVIDUAL_EDIT_REQUEST_STARTED
    });
    yield put({ type: ActionTypes.SHOW_SPINNER });

    const { values } = action;
    const {
      user: { info }
    } = yield select();
    values.userType = info.userType;
    values.type = 'INDIVIDUAL';
    const params = { ...values };
    if (typeof params.birthDate === 'string') {
      params.birthDate = values.birthDate
    } else {
      params.birthDate = values.birthDate.format('YYYY-MM-DD');
    }

    if (values.passportExpiry) {

      if (typeof params.birthDate === 'string') {
        params.passportExpiry = values.passportExpiry
      } else {
        params.passportExpiry = values.passportExpiry.format('YYYY-MM-DD');

      }
    }
    const { response, request, status } = yield call(
      Api.Registration.investorEdit,
      `${utils.config.apiUserUrl}/edit`,
      params
    );
    const isSpCheck = yield call(isSp);
    const notificationClass = isSpCheck
      ? ''
      : 'ant-notification-success ant-notification-notice-description';
    if (request.status && (request.status === 200 || request.status === 204)) {
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: { description: 'Edits saved successfully, your changes will be reviewed.' }
      });
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({
      type: ActionTypes.INVESTOR_INDIVIDUAL_EDIT_REQUEST_FINISHED
    });
  }
}

function* advisorRegistration(data) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const isSpCheck = yield call(isSp);
  const notificationClass = isSpCheck
    ? ''
    : 'ant-notification-success ant-notification-notice-description';
  try {
    let reCaptchaToken = sessionStorage.getItem("recaptchaToken");
    const { response, request, status } = yield call(
      Api.Registration.userRegistration,
      `${utils.config.apiUserUrl}/public/register`,
      { data: { ...data.values, reCaptchaToken: reCaptchaToken } }
    );
    if (
      request.status
      && (request.status === 200 || request.status === 201 || request.status === 204)
    ) {
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: {
          field: data.values.email,
          code: 6
        },
        staticNotification: true
      });
      yield history.push('/login');
    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* advisorEdit(data) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const isSpCheck = yield call(isSp);
  const notificationClass = isSpCheck
    ? ''
    : 'ant-notification-success ant-notification-notice-description';
  try {
    const { response, request, status } = yield call(
      Api.Registration.userRegistration,
      `${utils.config.apiUserUrl}/edit`,
      { data: { ...data.values } }
    );
    if (
      request.status
      && (request.status === 200 || request.status === 201 || request.status === 204)
    ) {
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: { description: 'Edits saved successfully, your changes will be reviewed.' }
      });
      yield put({
        type: sharedActionTypes.USER_INFORMATION_REQUEST
      });
    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

const privateBankerRegistrationConfig = {
  spinner: true,
  requestFunction: Api.Registration.userRegistration,
  requestUrl: `${utils.config.apiUserUrl}/user/me/private-bankers`,
  onSuccess: {
    notification: {
      type: 'success',
      className: 'ant-notification-success ant-notification-notice-description',
      notificationType: 'notification',
      staticNotification: true,
      data: {
        code: '17'
      }
    },
    trigAction: [getPrivateBankersListAction(), togglePrivateBankerModal()]
  },
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
    trigAction: [togglePrivateBankerModal()]
  }
};

function* strategicPartnerRegistration(data) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const isSpCheck = yield call(isSp);
  const notificationClass = isSpCheck
    ? ''
    : 'ant-notification-success ant-notification-notice-description';
  try {
    let reCaptchaToken = sessionStorage.getItem("recaptchaToken");
    const { response, request, status } = yield call(
      Api.Registration.userRegistration,
      `${utils.config.apiUserUrl}/public/apply`,
      {
        data: {
          ...data.value,
          type: 'COMPANY',
          status: 'APPLIED',
          userType: 'ADMIN_STRATEGIC_PARTNER',
          reCaptchaToken: reCaptchaToken
        }
      }
    );
    if (request.status && (request.status === 200 || request.status === 204)) {
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: { description: 'Information is sent to FinBursa Admin user' }
      });

      const loginUrl = `${process.env.REACT_APP_PROTOCOL}//${process.env.REACT_APP_WEB_URL}${login}`;
      window.location.href = `${loginUrl}?registrationSp=success`;


    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

const FILES_NAMES = {
  BACKGROUND_PICTURE: 'BACKGROUND_PICTURE',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  TERMS_OF_SERVICE: 'TERMS_OF_SERVICE',
  LOGO: 'LOGO'
};

const fieldFileTypeMap = {
  backgroundPicture: FILES_NAMES.BACKGROUND_PICTURE,
  privacyPolicyDocument: FILES_NAMES.PRIVACY_POLICY,
  termsOfServiceDocument: FILES_NAMES.TERMS_OF_SERVICE,
  logo: FILES_NAMES.LOGO
};

function* strategicPartnerFilesUpload({ values, successAction }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const isSpCheck = yield call(isSp);
  const notificationClass = isSpCheck
    ? ''
    : 'ant-notification-success ant-notification-notice-description';
  const uploadTasks = Object.entries(values.files).reduce((acc, [field, file]) => {
    if (file) {
      acc.push(call(Api.Registration.uploadSPPlatformConfigFile, fieldFileTypeMap[field], file));
    }

    return acc;
  }, []);

  try {
    const results = yield all(uploadTasks);

    const isFilesUploadSuccess = _.every(
      results,
      ({ request }) => request.status && (request.status === 200 || request.status === 204)
    );

    if (isFilesUploadSuccess) {
      const filesData = results.reduce((acc, { response }) => {
        const fieldName = [_.invert(fieldFileTypeMap)[response.type]];
        return {
          ...acc,
          [fieldName]: response
        };
      }, {});

      values.files = undefined;

      const strategicPartnerModel = {
        ...values,
        ...filesData
      };

      yield put(successAction(strategicPartnerModel));
    } else {
      const errorTasks = [];

      results.forEach(({ request, response }) => {
        if (!(request.status && (request.status === 200 || request.status === 204))) {
          errorTasks.push(
            call(notification, {
              type: 'error',
              className: notificationClass,
              data: response
            })
          );
        }
      });

      yield all(errorTasks);
    }
  } catch (e) {
    console.log(e);
  }

  yield put({ type: ActionTypes.HIDE_SPINNER });
}

function* updateSpPlatformConfig({ config }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  const isSpCheck = yield call(isSp);
  console.log("isSp", isSpCheck)
  const notificationClass = isSpCheck
    ? ''
    : 'ant-notification-success ant-notification-notice-description';
  try {
    const { response, request } = yield call(Api.Registration.updateSpPlatformConfig, config);

    if (request.status && (request.status === 200 || request.status === 204)) {
      yield put({
        type: ActionTypes.UPDATE_SP_PLATFORM_CONFIG_SUCCESS
      });

      const { user } = yield select();
      const subDomain = user && user.info && user.info.subDomain;


      if (subDomain) {
        yield put(getSpPlatformConfig(subDomain));
      }

      const description = 'Platform settings have been successfully updated';
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: { description }
      });
    } else {
      yield put({
        type: ActionTypes.UPDATE_SP_PLATFORM_CONFIG_FAILURE
      });

      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response
      });
    }
  } catch (e) {
    console.log('error: ', { e });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

export default function* registrationWatch() {
  yield takeLatest(ActionTypes.ISSUER_REGISTRATION_REQUEST, issuerRegistration);
  yield takeLatest(ActionTypes.ISSUER_EDIT_REQUEST, issuerEdit);
  yield takeLatest(ActionTypes.INVESTOR_REGISTRATION_REQUEST, investorRegistration);
  yield takeLatest(ActionTypes.INVESTOR_COMPANY_REGISTRATION_REQUEST, investorCompanyRegistration);
  yield takeLatest(ActionTypes.INVESTOR_COMPANY_EDIT_REQUEST, investorCompanyEdit);
  yield takeLatest(
    ActionTypes.INVESTOR_INDIVIDUAL_REGISTRATION_REQUEST,
    investorIndividualRegistration
  );
  yield takeLatest(
    ActionTypes.INVESTOR_INDIVIDUAL_EDIT_REQUEST,
    investorIndividualEdit
  );
  yield takeLatest(ActionTypes.ADVISOR_REGISTRATION_REQUEST, advisorRegistration);
  yield takeLatest(ActionTypes.ADVISOR_EDIT_REQUEST, advisorEdit);
  yield takeLatest(ActionTypes.STRATEGIC_PARTNER_REQUEST, strategicPartnerRegistration);
  yield takeLatest(ActionTypes.STRATEGIC_PARTNER_FILES_UPLOAD, strategicPartnerFilesUpload);
  yield takeLatest(ActionTypes.UPDATE_SP_PLATFORM_CONFIG, updateSpPlatformConfig);
  yield takeLatest(
    ActionTypes.PRIVATE_BANKER_REGISTRATION_REQUEST,
    patternSaga,
    privateBankerRegistrationConfig
  );
}
