import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';
import notification from '../utils/notifications';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import {isSp} from '../utils/isSp'

function* getTemplate({ templateType }) {
  const getTemplateUrl = `${utils.config.apiTemplateUrl}?type=${templateType}`;

  yield put({ type: ActionTypes.SHOW_SPINNER });

  const { request, response } = yield call(Api.Template.getTemplate, getTemplateUrl);

  if (request && request.status === 200) {
    if (response && response.link) {
      window.location.href = response.link;
    }
  } else {
    yield call(notification, {
      type: 'error',
      data: { description: "File wasn't downloaded. Please try again." }
    });
  }

  yield put({ type: ActionTypes.HIDE_SPINNER });
}

function* getNDATemplate({ payload: { url = 'mutual-agreement/templates', id = '' } }) {
  const projectId = id || '';
  const downloadUrl = `${utils.config.apiPandaDocUrl}/${url}/${projectId}`;

  yield put({ type: ActionTypes.SHOW_SPINNER });

  const { request, response, status } = yield call(Api.Template.getNDATemplate, downloadUrl);

  if (request && request.status === 200) {
    let a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(response);
    a.download = 'download.pdf';
    a.click();
  } else if (request && request.status === 401) {
    console.log('response :', response);
    yield call(notification, {
      type: 'error',
      data: { code: 6 },
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',
    });
    yield put({
      type: ActionTypes.USER_LOGOUT_REQUEST
    });
  } else {
    yield call(notification, {
      type: 'error',
      data: { description: "File wasn't downloaded. Please try again." }
    });
  }

  yield put({ type: ActionTypes.HIDE_SPINNER });
}

export default function* watchGetTemplate() {
  yield takeLatest(ActionTypes.GET_TEMPLATE, getTemplate);
  yield takeLatest(ActionTypes.GET_NDA_TEMPLATE, getNDATemplate);
}
