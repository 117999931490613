import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import patternSaga from './patternSaga';
import * as pathname from 'shared/routes/urlLocations';
import { isSp } from '../utils/isSp'

const GetOngoingSPProjectsConfig = {
  requestFunction: Api.Projects.getSpProjects,
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
  }
};

const GetFinishedSPProjectsConfig = {
  requestFunction: Api.Projects.getSpProjects,
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
  }
};

const GetRejectedSPProjectsSagaConfig = {
  requestFunction: Api.Projects.getSpProjects,
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
  }
};

const GetPendingSPProjectsSagaConfig = {
  requestFunction: Api.Projects.getSpProjects,
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
  }
};

const GetPublicSPProjectsSagaConfig = {
  requestFunction: Api.Projects.getSpProjects,
  onFailure: {
    redirectTo: pathname.homePage,
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
  }
};

const projectApproveSagaConfig = {
  spinner: true,
  requestFunction: Api.Projects.approveProject,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
  onSuccess: {
    redirectTo: [pathname.spProjects, { activeTab: '3' }],
    notification: {
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',
      staticNotification: true,
      data: {
        code: "12"
      }
    }
  },
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
  }
};

const projectRejectSagaConfig = {
  spinner: true,
  requestFunction: Api.Projects.rejectProject,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
  onSuccess: {
    redirectTo: [pathname.spProjects, { activeTab: '3' }],
    notification: {
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',
      staticNotification: true,
      data: {
        code: '13'
      }
    }
  },
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',
    },
  }
};

const projectsShowSagaConfig = {
  requestFunction: Api.Projects.toggleShowStatus,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
};

export default function* watchSPProjectsSaga() {
  yield takeLatest(ActionTypes.PROJECT_REJECT_REQUEST, patternSaga, projectRejectSagaConfig);
  yield takeLatest(ActionTypes.PROJECT_APPROVE_REQUEST, patternSaga, projectApproveSagaConfig);
  yield takeLatest(ActionTypes.GET_ONGOING_SP_PROJECTS, patternSaga, GetOngoingSPProjectsConfig);
  yield takeLatest(ActionTypes.GET_FINISHED_SP_PROJECTS, patternSaga, GetFinishedSPProjectsConfig);
  yield takeLatest(
    ActionTypes.GET_PENDING_SP_PROJECTS,
    patternSaga,
    GetPendingSPProjectsSagaConfig
  );
  yield takeLatest(
    ActionTypes.GET_REJECTED_SP_PROJECTS,
    patternSaga,
    GetRejectedSPProjectsSagaConfig
  );
  yield takeLatest(ActionTypes.GET_PUBLIC_SP_PROJECTS_REQUEST, patternSaga, GetPublicSPProjectsSagaConfig);
  yield takeLatest(ActionTypes.PROJECT_SHOW_TOGGLE_REQUEST, patternSaga, projectsShowSagaConfig);
}
