import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import patternSaga from './patternSaga';
import { isSp } from '../utils/isSp'

const tagsSagaConfig = {
  requestFunction: Api.Projects.getAllTags,
  requestUrl: `${utils.config.apiProjectUrl}/tags`,
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
  }
};

export function* watchTagsSaga() {
  yield takeLatest(ActionTypes.GET_ALL_TAGS, patternSaga, tagsSagaConfig);
}
