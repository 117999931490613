import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import Api from '../api';
import patternSaga from './patternSaga';
import { isSp } from '../utils/isSp';

const projectInvestmentsSagaConfig = {
  requestFunction: Api.Projects.getInvestmentsList,
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
  }
};

export default function* watchProjectInvestments() {
  yield takeLatest(
    ActionTypes.GET_PROJECT_INVESTMENTS_LIST,
    patternSaga,
    projectInvestmentsSagaConfig
  );
}
