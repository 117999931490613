import styled from 'styled-components';
import SharedLayouts from 'shared/styled/layouts';
import Layout from 'antd/lib/layout';
import theme from 'shared/styled/theme';
import { lightenColor } from 'shared/utils/colorUtils';
const { Sider } = Layout;

const StyledSider = styled(Sider)`
   background: ${({ isSpCheck, accentColor }) =>
      isSpCheck 
        ? lightenColor(accentColor, 0.7) 
        : '#0E181D'} !important;
  .submenu-item-name {
    margin: 0 0 0 10px !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: ${({ isSpCheck, accentColor }) =>
      isSpCheck 
        ? '#F2F2F2'
        : '#122027'} !important;
    border-left: ${({ isSpCheck, accentColor }) =>
      isSpCheck 
        ? `3.8px solid ${accentColor};`
        : '3.8px solid #1D3B49;'} !important;
  }


  .ant-menu-item:hover {
    background: ${({ isSpCheck, accentColor }) =>
      isSpCheck 
        ? '#F2F2F2'
        : '#122027'} !important;
        
    border-left: 2px solid #1D3B49; ${({ isSpCheck, accentColor }) =>
      isSpCheck 
        ? '2px solid accentColor'
        : '2px solid #1D3B49'} !important;
}


`;

export default {
  MainLayout: SharedLayouts.MainLayout,
  Sider: StyledSider
};
