import { takeLatest } from 'redux-saga/effects';
import { call, put } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import Api from '../api';
import utils from '../utils';
import notification from '../utils/notifications';
import { history, login } from 'shared/routes/urlLocations';
import { isSp } from '../utils/isSp';
function* forgotPassword(action) {
  try {
    yield put({ type: ActionTypes.FORGOT_PASSWORD_REQUEST_STARTED });
    yield put({ type: ActionTypes.SHOW_SPINNER });

    const { email } = action;

    const { response, request, status } = yield call(
      Api.Auth.forgotPassword,
      `${utils.config.apiUserUrl}/public/password/forgot`,
      email
    );
    const isSpCheck = yield call(isSp);
    const notificationClass = isSpCheck
            ? ''
            : 'ant-notification-success ant-notification-notice-description';

    if (request.status && request.status === 200) {
      yield call(notification, {
        type: 'success',
        className: notificationClass,
        data: response,
        status
      });
      yield history.push(login);
    } else if (
      request.status &&
      (request.status === 500 || request.status === 502 || request.status === 503)
    ) {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: [{ description: 'An error occurred. Please, try again' }]
      });
    } else {
      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: response,
        status
      });
    }
  } catch (e) {
    const isSpCheck = yield call(isSp);
    const notificationClass = isSpCheck
    ? ''
    : 'ant-notification-success ant-notification-notice-description';
    yield call(notification, {
      type: 'error',
      className: notificationClass,
      data: e.message
    });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({ type: ActionTypes.FORGOT_PASSWORD_REQUEST_FINISHED });
  }
}

export default function* watchForgotPassword() {
  yield takeLatest(ActionTypes.FORGOT_PASSWORD_REQUEST, forgotPassword);
}
