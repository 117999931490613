import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import { LoginConfig } from '../utils/config';
import networkLayer from '../utils/networkLayer';
import Api from '../api';
import utils from '../utils';
import getParameterByName from 'shared/utils/getParameterByName';
import notification from '../utils/notifications';
import { history } from 'shared/routes/urlLocations';
import { fetchUserInformation } from 'shared/actions/userDataActions';
import capitalize from 'lodash/capitalize';
import { pushNotificationsConnectAction } from '../actions/pushNotificationsActions';
import { getSpPlatformPublicConfig } from "shared/actions/spPlatformConfigActions";
import { isSp } from '../utils/isSp';

const API_URL = utils.config.apiUrlAuth;

function* login(action) {
  const authorizeCodedHeader = `Basic ${btoa(`${action.data.email}:${action.data.password}`)}`;
  const tokenCodedHeader = `Basic ${btoa(`${LoginConfig.CLIENT_ID}:${LoginConfig.CLIENT_SECRET}`)}`;
  const user = Api.User;
  yield put({ type: ActionTypes.SHOW_SPINNER });
  let sub_domain = window.location.hostname.split('.')[0];

  let reCaptchaToken = sessionStorage.getItem("recaptchaToken");
  const params = { ...LoginConfig.PARAMS.auth };
  const {
    user: {
      strategicPartners: { exists }
    },
    spPlatform: { config: spPlatformConfig }
  } = yield select();

  // Uncomment to use spp domain
  /* if (!exists) {
    params.sub_domain = 'spp-qa';
  } */

  if (exists) {
    params.sub_domain = sub_domain;
  }

  if (reCaptchaToken) {
    params.reCaptchaToken = reCaptchaToken;
  }

  let { response, request, status } = yield call(
    networkLayer.postJson,
    `${API_URL}/oauth/authorize`,
    {
      headers: { ...LoginConfig.HEADERS, Authorization: authorizeCodedHeader },
      params
    }
  );
  if (request && request.status === 200) {
    const { responseURL } = request;
    const code = getParameterByName('code', responseURL);
    sub_domain = exists ? { sub_domain } : { sub_domain: '' };

    const token = yield call(networkLayer.postJson, `${API_URL}/oauth/token`, {
      headers: { ...LoginConfig.HEADERS, Authorization: tokenCodedHeader },
      params: { ...LoginConfig.PARAMS.token, code, ...sub_domain }
    });
    
    if (token.response && token.request.status === 200) {
      const isSpCheck = yield call(isSp);
      const notificationClass = isSpCheck
        ? 'ant-notification-sp'
        : 'ant-notification-success ant-notification-notice-description';


      yield user.info(token.response);
      const referer = user.referer(history).referer;
      yield put({ type: ActionTypes.USER_LOGIN_SUCCESS, message: { ...token.response } });
      yield put(fetchUserInformation());
      const welcomeSubdomain = spPlatformConfig && spPlatformConfig.subDomain ? spPlatformConfig.subDomain : 'Finbursa';
      yield put(getSpPlatformPublicConfig(sub_domain.sub_domain));
      yield call(notification, {
        type: 'success',
        data: `Welcome to ${capitalize(welcomeSubdomain)}!`,
        status: 'success',
        className: notificationClass,
      });
      if (token.response.usCitizen) {
        history.push('/sp-projects');
      } 
      else if (referer.hasProjectId) {
        const projectId = referer.projectId;

        history.push(referer.pathname);
        yield put({ type: ActionTypes.PROJECT_FIRST_TIME_VIEW_CHECK, payload: { projectId } });
      } else {
        history.push(referer.pathname);
      }
      yield put(pushNotificationsConnectAction());
      yield put({ type: ActionTypes.HIDE_SPINNER });
    } else {
      yield put({ type: ActionTypes.USER_LOGIN_FAILURE });
      yield put({ type: ActionTypes.HIDE_SPINNER });
      yield call(notification, { type: 'error', data: token.response, status });
    }
  } else {
    const isSpCheck = yield call(isSp);
    const notificationClass = isSpCheck
      ? ''
      : 'ant-notification-success ant-notification-notice-description';
    yield put({ type: ActionTypes.USER_LOGIN_FAILURE });
    yield put({ type: ActionTypes.HIDE_SPINNER });
    if (response && response[0].description === "Access denied") {

      yield call(notification, {
        type: 'error',
        className: notificationClass,
        data: { description: 'Your account has been deactivated. Please contact our support for assistance.' },
        status
      });
    }
    yield call(notification, {
      type: 'error',
      className: notificationClass,
      data: { description: 'Invalid email or password, please try again.' },
      status
    });
  }
}

export default function* watchLogin() {
  yield takeLatest(ActionTypes.USER_LOGIN_REQUEST, login);
}



