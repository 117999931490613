import { takeLatest } from 'redux-saga/effects';
import { ActionTypes as ActionTypesShared } from 'shared/constants/';
import Api from '../api';
import patternSaga from './patternSaga';
import utils from 'shared/utils';
import { isSp } from '../utils/isSp'

const getUserPlatformCommissionConfig = {
  requestFunction: Api.User.getUserPlatformCommission,
  requestUrl: `${utils.config.apiPlatformConfigUrl}/escrow-commission`
};

const putUserPlatformCommissionConfig = {
  requestFunction: Api.User.putUserPlatformCommission,
  requestUrl: `${utils.config.apiPlatformConfigUrl}/escrow-commission`,
  onSuccess: {
    trigAction: { type: ActionTypesShared.GET_USER_PLATFORM_COMMISSION },
    notification: {
      staticNotification: true,
      data: {
        code: '0'
      }
    }
  },
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
  }
};

export default function* watchUserPlatform() {
  yield takeLatest(
    ActionTypesShared.GET_USER_PLATFORM_COMMISSION,
    patternSaga,
    getUserPlatformCommissionConfig
  );
  yield takeLatest(
    ActionTypesShared.PUT_USER_PLATFORM_COMMISSION,
    patternSaga,
    putUserPlatformCommissionConfig
  );
}
