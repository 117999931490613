import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import notification from '../utils/notifications';
import Api from '../api';
import { history } from 'shared/routes/urlLocations';
import setUnloginedUserType from '../utils/setUnloginedUserType';
import { isSp } from '../utils/isSp';

export function* confirmationEmail(action) {
  try {
    yield put({ type: ActionTypes.CONFIRM_EMAIL_REQUEST_STARTED });
    yield put({ type: ActionTypes.SHOW_SPINNER });
    const {
      user: {
        strategicPartners: { exists }
      }
    } = yield select();

    const { token, values } = action;
    const { response, status } = yield call(
      Api.Registration.confirmationEmail,
      `${utils.config.apiUserUrl}/public/password/${exists ? 'set' : 'confirm'}/${token}`,
      { ...values }
    );

    if (status === 'success') {
      const isSpCheck = yield call(isSp);
      const notificationClass = isSpCheck
        ? ''
        : 'ant-notification-success ant-notification-notice-description';
      yield call(notification, {
        type: 'success',
        data: { code: 3 },
        className: notificationClass,
        staticNotification: true
      });
      yield call(setUnloginedUserType, response.userType);
      yield history.push('/login');
    } else {
      yield call(notification, {
        type: 'error',
        data: response
      });
    }
  } catch (error) {
    yield call(notification, {
      type: 'error',
      data: error.message
    });
  } finally {
    yield put({ type: ActionTypes.HIDE_SPINNER });
    yield put({ type: ActionTypes.CONFIRM_EMAIL_REQUEST_FINISHED });
  }
}

export function* checkConfirmToken({ token }) {
  try {
    yield put({ type: ActionTypes.CONFIRM_EMAIL_REQUEST_STARTED });

    const { response, status } = yield call(
      Api.Registration.checkConfirmToken,
      `${utils.config.apiUserUrl}/public/${token}`
    );
    if (status === 'error') {
      yield call(notification, {
        type: 'error',
        data: response
      });
      yield history.push('/login');
    }
    else{
      yield put({ type: ActionTypes.CONFIRM_EMAIL_REQUEST_SUCCESS,
        payload: {
          userType: response.data.userType
        }});
    }
  } catch (e) {
    yield call(notification, {
      type: 'error',
      data: e.message
    });
  } finally {

    yield put({ type: ActionTypes.CONFIRM_EMAIL_REQUEST_FINISHED });
  }
}

export function* watchConfirmationEmail() {
  yield takeLatest(ActionTypes.CONFIRM_EMAIL_REQUEST, confirmationEmail);
}

export function* watchCheckConfirmToken() {
  yield takeLatest(ActionTypes.CHECK_CONFIRM_EMAIL_TOKEN_REQUEST, checkConfirmToken);
}
