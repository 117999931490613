import { delay } from 'redux-saga';
import { takeLatest } from 'redux-saga/effects';
import { call, put, select } from 'redux-saga/effects';
import { ActionTypes as ActionTypesShared } from 'shared/constants';
import { ActionTypes } from '../constants';
import PROJECT_TYPES from '../constants/projectTypes';
import Api from '../api';
import utils from '../utils';
import notification from '../utils/notifications';
import { projectsApi, history } from 'shared/routes/urlLocations';
import { convertCodeToMessage } from '../utils/responseCodeToMessage';
import patternSaga from './patternSaga';
import { projectDetails, projectEdit } from 'shared/routes/urlLocations';
import IsUser from 'shared/utils/user/IsUser';
import { isSp } from '../utils/isSp';

function* removeProject({ id, page }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });

  const {
    user: {
      info: { userType }
    }
  } = yield select();

  try {
    yield put({
      type: ActionTypes.REMOVE_PROJECT_STARTED,
      payload: {
        requestFrom: 'removeProject'
      }
    });

    const result = yield call(Api.Projects.removeProject, `${projectsApi}/${id}`);
    if (result.request && result.request.status === 200) {
      yield put({
        type: ActionTypes.REMOVE_PROJECT_SUCCESS,
        message: result
      });

      const isUser = new IsUser(userType);
      if (userType && (isUser.issuer || isUser.issuerSP)) {
        yield put({
          type: ActionTypes.ISSUER_GET_SAVED_PROJECTS,
          requestConfig: {
            projectsType: PROJECT_TYPES.SAVED,
            projection: 'issuerSaved',
            sort: 'sortTitle',
            page: (page && page.number) || 0,
            size: 10
          }
        });
      } else {
        yield put({
          type: ActionTypes.GET_SAVED_SP_PROJECTS,
          requestConfig: {
            page: (page && page.number) || 0,
            sort: 'createdAt,desc',
            size: 20,
            projection: 'spSaved',
            url: 'saved'
          }
        });
      }
    } else if (result && result.status === 401) {
      yield sessionStorage.clear();
      yield history.push('/');
      yield call(notification, {
        type: 'error',
        data: 'Please, authorize again!'
      });
    } else {
      yield put({
        type: ActionTypes.REMOVE_PROJECT_FAILURE,
        message: convertCodeToMessage(result.response.error.code)
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.REMOVE_PROJECT_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.REMOVE_PROJECT_FINISHED,
      payload: {
        requestFrom: 'removeProject'
      }
    });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* closeProject({id, data}) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  try {
    yield put({
      type: ActionTypes.PROJECT_CLOSE_STARTED,
    });
    const result = yield call(Api.Projects.closeProject, `${projectsApi}/${id}/close`, {fundingStatus :data.status, fundedAmount: data.amount});

    if (result.request && result.request.status === 204) {
      yield put({
        type: ActionTypes.PROJECT_CLOSE_SUCCESS,
      });

      yield put({
        type: ActionTypes.ISSUER_GET_ONGOING_PROJECTS,
        requestConfig: {
          projectsType: PROJECT_TYPES.ONGOING,
          projection: 'issuerPublished',
          sort: 'sortTitle',
          page:  0,
          size: 10
        }
      });
      yield put({
        type: ActionTypes.ISSUER_GET_FINISHED_PROJECTS,
        requestConfig: {
          projectsType: PROJECT_TYPES.FINISHED,
          projection: 'issuerPublished',
          sort: 'sortTitle',
          page:  0,
          size: 10
        }
      });
      const isSpCheck = yield call(isSp);
      const notificationClass = isSpCheck
        ? 'ant-notification-sp'
        : 'ant-notification-success ant-notification-notice-description';

      yield call(notification, {
        type: 'success',
        data: {description: 'Your project was successfully closed.'},
        className: notificationClass,

      });

      }
     else if (result && result.status === 401) {
      yield sessionStorage.clear();
      yield history.push('/');
      yield call(notification, {
        type: 'error',
        data: 'Please, authorize again!'
      });
    } else {
      yield put({
        type: ActionTypes.PROJECT_CLOSE_FAILURE,
        message: convertCodeToMessage(result.response.error.code)
      });
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.PROJECT_CLOSE_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.PROJECT_CLOSE_FINISHED,
    });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

const projectAllDataSagaConfig = {
  requestFunction: Api.Projects.getAllProjectData,
  requestUrl: `${utils.config.apiProjectUrl}/projects`
};

const projectsFeaturedSagaConfig = {
  requestFunction: Api.Projects.toggleFeaturedStatus,
  requestUrl: `${utils.config.apiProjectUrl}/projects`,
};

const projectRejectionsHistorySagaConfig = {
  requestFunction: Api.Projects.getProjectRejectionsHistory,
  requestUrl: `${utils.config.apiProjectUrl}/projects`
};

const getUsersProjectsSagaConfig = {
  requestFunction: Api.Projects.getUsersProjects,
  requestUrl: `${utils.config.apiProjectUrl}/projects`
};

const getInvestorsProjectsSagaConfig = {
  requestFunction: Api.Projects.getUsersProjects,
  requestUrl: `${utils.config.apiProjectUrl}/projects/investor/my-projects`
};

const getInvestorsFavoriteProjectsSagaConfig = {
  requestFunction: Api.Projects.getUsersProjects,
  requestUrl: `${utils.config.apiProjectUrl}/projects/investor`,
  spinner: true
};

const getAdvisorProjectsSagaConfig = {
  requestFunction: Api.Projects.getUsersProjects,
  requestUrl: `${utils.config.apiProjectUrl}/projects/advisor/my-projects`
};

function* isFirstTimeView({ payload: { projectId } }) {
  yield put({ type: ActionTypes.SHOW_SPINNER });
  yield call(delay, 2000);

  try {
    yield put({ type: ActionTypes.SHOW_SPINNER });
    yield put({
      type: ActionTypes.PROJECT_FIRST_TIME_VIEW_CHECK_STARTED
    });
    const isUser = new IsUser(sessionStorage.userType);

    if (isUser.investor || isUser.advisor || isUser.investorSP) {
      const result = yield call(
        Api.Projects.isFirstTimeView,
        `${utils.config.apiProjectUrl}/projects/${projectId}/is-first-time-view`
      );
      if (result.request && result.request.status === 200) {
        const { firstTimeView } = result.response;
        yield put({
          type: ActionTypes.PROJECT_FIRST_TIME_VIEW_CHECK_SUCCESS
        });
        if (firstTimeView) {
          yield put({
            type: ActionTypes.TOGGLE_FIRST_TIME_VIEW_MODAL,
            payload: { projectId }
          });
        } else {
          yield history.push(`${projectDetails}/${projectId}`);
        }
      } else if (result && result.request.status === 401) {
        yield sessionStorage.clear();
        yield history.push('/');
        yield put({
          type: ActionTypes.PROJECT_FIRST_TIME_VIEW_CHECK_FAILURE,
          message: 'Please, authorize again!'
        });
      } else {
        yield put({
          type: ActionTypes.PROJECT_FIRST_TIME_VIEW_CHECK_FAILURE,
          message: convertCodeToMessage(result.response.error.code)
        });
        yield call(notification, {
          type: 'error',
          data: result.response
        });
      }
    } else {
      yield history.push(`${projectDetails}/${projectId}`);
    }
  } catch ({ message }) {
    yield put({
      type: ActionTypes.PROJECT_FIRST_TIME_VIEW_CHECK_FAILURE,
      message
    });
  } finally {
    yield put({
      type: ActionTypes.PROJECT_FIRST_TIME_VIEW_CHECK_FINISHED
    });
    yield put({ type: ActionTypes.HIDE_SPINNER });
  }
}

function* getIsProjectFinished({ projectId, historyPush }) {
  const {
    user: {
      info: { userType }
    }
  } = yield select();

  yield put({ type: ActionTypesShared.IS_PROJECT_FINISHED_STARTED });
  yield put({ type: ActionTypesShared.SHOW_SPINNER });
  try {
    const { response, request, status } = yield call(
      Api.Projects.getIsProjectFinished,
      `${utils.config.apiProjectUrl}/projects/${projectId}?projection=finished`
    );

    if (request.status && request.status === 200) {
      yield put({ type: ActionTypesShared.IS_PROJECT_FINISHED_SUCCESS });

      const isUser = new IsUser(userType);
      if (isUser.allIssuers && historyPush === true) {
        if (response.finished === true) {
          yield history.push({ pathname: `${projectDetails}/${projectId}` });
        } else {
          yield history.push({ pathname: `${projectEdit}/${projectId}` });
        }
      }
    } else if (request && request.status === 401) {
      yield call(notification, { type: 'error', data: response, status });
      yield put({ type: ActionTypesShared.USER_LOGOUT_REQUEST });
    } else {
      yield put({ type: ActionTypesShared.IS_PROJECT_FINISHED_FAILURE });
      yield call(notification, { type: 'error', data: response });
    }
  } catch (e) {
    console.log(e);
  } finally {
    yield put({ type: ActionTypesShared.IS_PROJECT_FINISHED_FINISHED });
    yield put({ type: ActionTypesShared.HIDE_SPINNER });
  }
}

function* getProjectUpdateLike({ projectId, param }) {
  yield put({ type: ActionTypes.INVESTOR_UPDATE_LIKE_PROJECT_STARTED, param });
  try {
    const { response, request } = yield call(
      Api.Projects.updateLike,
      `${utils.config.apiProjectsUrl}/${projectId}/${param}`
    );

    if (request.status && request.status === 204) {
      yield put({ type: ActionTypes.INVESTOR_UPDATE_LIKE_PROJECT_SUCCESS });
    } else {
      yield put({ type: ActionTypes.INVESTOR_UPDATE_LIKE_PROJECT_FAILURE });
      yield call(notification, { type: 'error', data: response });
    }
  } catch (e) {
    console.log(e);
  }
}

function* getProjectUpdateFavorite({ projectId, param }) {
  yield put({ type: ActionTypes.INVESTOR_UPDATE_FAVORITE_PROJECT_STARTED, param });
  try {
    const { response, request } = yield call(
      Api.Projects.updateFavorite,
      `${utils.config.apiProjectsUrl}/${projectId}/${param}`
    );

    if (request.status && request.status === 204) {
      yield put({ type: ActionTypes.INVESTOR_UPDATE_FAVORITE_PROJECT_SUCCESS });
    } else {
      yield put({ type: ActionTypes.INVESTOR_UPDATE_FAVORITE_PROJECT_FAILURE });
      yield call(notification, { type: 'error', data: response });
    }
  } catch (e) {
    console.log(e);
  }
}

export default function* watchIssuerProjects() {
  yield takeLatest(ActionTypes.REMOVE_PROJECT, removeProject);
  yield takeLatest(ActionTypes.PROJECT_CLOSE_REQUEST, closeProject);
  yield takeLatest(ActionTypes.PROJECT_FIRST_TIME_VIEW_CHECK, isFirstTimeView);
  yield takeLatest(ActionTypes.PROJECT_ALL_DATA_GET_REQUEST, patternSaga, projectAllDataSagaConfig);
  yield takeLatest(
    ActionTypes.PROJECT_REJECTIONS_HISTORY_GET_REQUEST,
    patternSaga,
    projectRejectionsHistorySagaConfig
  );
  yield takeLatest(ActionTypes.ISSUER_GET_SAVED_PROJECTS, patternSaga, getUsersProjectsSagaConfig);
  yield takeLatest(
    ActionTypes.ISSUER_GET_ONGOING_PROJECTS,
    patternSaga,
    getUsersProjectsSagaConfig
  );
  yield takeLatest(
    ActionTypes.ISSUER_GET_FINISHED_PROJECTS,
    patternSaga,
    getUsersProjectsSagaConfig
  );
  yield takeLatest(
    ActionTypes.ISSUER_GET_PENDING_PROJECTS,
    patternSaga,
    getUsersProjectsSagaConfig
  );
  yield takeLatest(
    ActionTypes.SP_ISSUER_GET_SAVED_PROJECTS,
    patternSaga,
    getUsersProjectsSagaConfig
  );

  yield takeLatest(
    ActionTypes.GET_ADVISOR_FINISHED_PROJECTS,
    patternSaga,
    getAdvisorProjectsSagaConfig
  );

  yield takeLatest(
    ActionTypes.GET_ADVISOR_SUCCESSFULLY_FINISHED_PROJECTS,
    patternSaga,
    getAdvisorProjectsSagaConfig
  );

  yield takeLatest(
    ActionTypes.GET_ADVISOR_ONGOING_PROJECTS,
    patternSaga,
    getAdvisorProjectsSagaConfig
  );

  yield takeLatest(
    ActionTypes.GET_INVESTORS_FINISHED_PROJECTS,
    patternSaga,
    getInvestorsProjectsSagaConfig
  );

  yield takeLatest(
    ActionTypes.GET_INVESTORS_FINISHED_FAVORITES_PROJECTS,
    patternSaga,
    getInvestorsFavoriteProjectsSagaConfig
  );

  yield takeLatest(
    ActionTypes.GET_INVESTORS_SUCCESSFULLY_FINISHED_PROJECTS,
    patternSaga,
    getInvestorsProjectsSagaConfig
  );

  yield takeLatest(
    ActionTypes.GET_INVESTORS_ONGOING_PROJECTS,
    patternSaga,
    getInvestorsProjectsSagaConfig
  );

  yield takeLatest(
    ActionTypes.GET_INVESTORS_ONGOING_FAVORITES_PROJECTS,
    patternSaga,
    getInvestorsFavoriteProjectsSagaConfig
  );

  yield takeLatest(
    ActionTypes.SP_ISSUER_GET_ONGOING_PROJECTS,
    patternSaga,
    getUsersProjectsSagaConfig
  );
  yield takeLatest(
    ActionTypes.SP_ISSUER_GET_FINISHED_PROJECTS,
    patternSaga,
    getUsersProjectsSagaConfig
  );
  yield takeLatest(
    ActionTypes.SP_ISSUER_GET_PENDING_PROJECTS,
    patternSaga,
    getUsersProjectsSagaConfig
  );
  yield takeLatest(ActionTypesShared.IS_PROJECT_FINISHED, getIsProjectFinished);
  yield takeLatest(ActionTypes.INVESTOR_UPDATE_LIKE_PROJECT, getProjectUpdateLike);
  yield takeLatest(ActionTypes.INVESTOR_UPDATE_FAVORITE_PROJECT, getProjectUpdateFavorite);
  yield takeLatest(ActionTypes.PROJECT_FEATURED_TOGGLE_REQUEST, patternSaga, projectsFeaturedSagaConfig);

}
