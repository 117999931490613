import { takeLatest } from 'redux-saga/effects';
import { ActionTypes } from '../constants';
import utils from '../utils';
import Api from '../api';
import patternSaga from './patternSaga';
import {isSp} from '../utils/isSp'



const projectsSearchGetResult = {
  spinner: true,
  requestFunction: Api.Search.getProjectsSearchResult,
  requestUrl: `${utils.config.apiProjectUrl}/projects/search`,
  onFailure: {
    notification: {
      type: "error",
      notificationType: "notification",
      staticNotification: true ,
      className: isSp() ? 'ant-notification-sp' : 'ant-notification-success ant-notification-notice-description',

    },
    
  
    redirectTo: '/sp-projects',
  }


};

export default function* watchSearchSaga() {
  yield takeLatest(ActionTypes.PROJECTS_SEARCH_GET_RESULT, patternSaga, projectsSearchGetResult);
}
